<template>
	<div>
		<!--main visual-->
		<div id="main_container">
			<div class="new-main">
				<div class="main-img">
					<div class="main-txt">
						<span><span class="logo">Hi<span class="light">gh</span>Pro</span> 플랫폼은 성공적인 프로젝트를 위해</span><br>
						인재평가, 추천, 관리에 이르는 토탈 솔루션을 제공합니다
					</div>
					<img src="/images/main_new_text.png">
				</div>
			</div>
		</div>
		<!--//main visual-->
		
		<div class="mainsection"> 
			<div class="process">
				<div class="tit">특화된 기능으로 검증된 인재를 확인하세요</div>
				<div class="process-list">
					<div class="process-box">
						<span class="i">
							<img src="/images/point_11.png">
						</span>
						<p>
							보상체계를 통한<br>
							자발적 경력관리
						</p>
					</div>
					<div class="process-box">
						<span class="i">
							<img src="/images/point_12.png">
						</span>
						<p>
							기술 및 직무능력 검증<br/>
							프로엔 평가모형 적용
						</p>
					</div>
					<div class="process-box">
						<span class="i">
							<img src="/images/point_13.png">
						</span>
						<p>
							인재평가 등급<br>
							기술인증서 발급
						</p>
					</div>
					<div class="process-box">
						<span class="i">
							<img src="/images/point_14.png">
						</span>
						<p>
							인재검색 및<br> 
							관심인재 등록
						</p>
					</div>
				</div>
			</div>
		</div>
		
		<div class="mainsection"> 
			<div class="project">
				<div class="tit">가능성을 가진 <span class="logo">Hi<span class="light">gh</span>Pro</span>과 함께 하세요</div>
				<div class="project-list">
					<div class="project-box">
						<div class="numeral">{{ data.indCount | number }}</div>
						<p>IT전문인재</p>
					</div>
					<div class="project-box">
						<div class="numeral">{{ data.projCount | number }}</div>
						<p>프로젝트</p>
					</div>
					<div class="project-box">
						<div class="numeral">{{ data.corpCount | number }}</div>
						<p>수행사</p>
					</div>
				</div>
			</div>
		</div>
		
		<div class="mainsection" style="background:#f3f3f3;"> 
			<div class="inner signup">
				<div class="list-signup">
					<div class="signup-meg">
						<p>
							<span>기술신고서를 등록해 보세요</span><br>							
							경력, 학력 등 기술신고서가<br>
							업데이트되면 포인트가 지급되요!<br><br>
						</p>
					</div>
					<div class="signup-btn">
						<span v-if="!common.loginNm" @click="$move('MEM003M02')">개인기술회원 등록하기</span>
						<span v-if="common.loginNm" @click="$move('SRH002M01')">기술신고서 조회·등록</span>
					</div>
				</div>
				<div class="list-signup">
					<div class="signup-meg">
						<p>
							<span>검증된 인재를 검색해 보세요</span><br>							
							경력별, 직무별<br>
							전문 기술인재를 검색해 보세요<br>
							인재예약도 가능합니다!
						</p>
					</div>
					<div class="signup-btn">
						<span v-if="!common.loginNm" @click="$move('MEM003M06')">기업담당회원 등록하기</span>
						<span v-if="common.loginNm" @click="$move('SRH002M01')">기술인재 검색·예약</span>
					</div>
				</div>
			</div>
		</div>
		
		<div class="mainsection partner">
			<div class="partner-inner">
				<div class="tit">다양한 분야의 수행사와 함께 합니다</div>
				<slide-comp type="image" :list="partnerImg" />
			</div>
		</div>
	</div>



</template>

<script>
import slideComp from "@/components/SlideComp.vue";
// @ is an alias to /src

export default {
	name: 'Home',

    components: {
        slideComp
    },
	data(){
		return { 
			data : {},
			common: {},
			partnerImg : ['/images/slide_banr_11.png', '/images/slide_banr_12.png']
		} 
	},
	created(){
		// this.$axios.post('/api/index.do', {})
		// .then(res => {
		//	 this.$data.data = res.data.data;
		//	 this.$data.common = res.data.common;
		//	 console.log(res); 
		// })
		// .catch(err => {
		//	 console.log(err);
		// });
	},

	methods : {
	}
}
</script>
<style scoped>
.swiper-container {
	margin-left: auto;
	margin-right: auto;
	position: relative;
	overflow: hidden;
	list-style: none;
	padding: 0;
	z-index: 1;
}
.swiper-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	-o-transition-property: transform;
	transition-property: transform;
	transition-property: transform,-webkit-transform;
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
}
.swiper-slide {
	width: 100%;
	height: 100%;
	position: relative;
	display: inline-block;
	flex-shrink: 0;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	-o-transition-property: transform;
	transition-property: transform;
	transition-property: transform,-webkit-transform;
}
.swiper-button-next, .swiper-button-prev {
	position: absolute;
	top: 50%;
	width: 27px;
	height: 44px;
	margin-top: -22px;
	z-index: 10;
	cursor: pointer;
	background-size: 27px 44px;
	background-position: center;
	background-repeat: no-repeat;
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
	background-image: url(/images/slider_right_arrow.png);
	right: 10px;
	left: auto;
}

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
	background-image: url(/images/slider_left_arrow.png);
	left: 10px;
	right: auto;
}
</style>
